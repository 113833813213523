import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create dashboard routes
import routes from "routes.js";

import image1 from "assets/img/full-screen-image-1.jpg";
import image2 from "assets/img/full-screen-image-2.jpg";
import image3 from "assets/img/full-screen-image-3.jpg";
import image4 from "assets/img/full-screen-image-4.jpg";
import Homepage from "views/Homepage";
import Dashboard from "views/Dashboard";
import Charts from "views/Charts";
import Tech from "views/Tech";
import Usecases from "views/Usecases";
import Contact from "views/Contact";
import Company from "views/Company";

function Admin() {
  const [sidebarImage, setSidebarImage] = React.useState(image3);
  const [sidebarBackground, setSidebarBackground] = React.useState("black");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      /*if (prop.collapse) {
        return getRoutes(prop.views);
      }*/
        return (
          <Route
            path={prop.path}
            key={key}
            component={prop.component}
          />
        );
    });
  };
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const dir = params.get('r');
  return (
    <>
      <div className="wrapper">
        <div className="main-panel" style={{width:"100%"}}>
          <AdminNavbar />
          <div className="content">
            {/*<Switch>{getRoutes(routes)}</Switch>*/}
            <Switch>
              <Route path="/home" key="homepage" component={Homepage}></Route>
              <Route path="/tech" key="tech" component={Tech}></Route>
              <Route path="/usecases" key="usecases" component={Usecases}></Route>
              {/*<Route path="/about" key="about" component={Company}></Route>*/}
              <Route path="/contact" key="contact" component={Contact}></Route>
              {dir=="contact" && <Redirect to="/contact"/>}
              {dir=="usecases" && <Redirect to="/usecases"/>}
              {dir=="tech" && <Redirect to="/tech"/>}
              {/*dir=="about" && <Redirect to="/about"/>*/}
              {dir=="home" && <Redirect to="/home"/>}
              <Redirect from="/" to="/home" />
            </Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
